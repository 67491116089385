<template>
  <b-container
    fluid
  >
    <b-row
      class="pb-5 mx-1"
    >
      <b-col
        v-if="Number(user?.allow_in) === 1"
        cols="12"
        class="px-0"
        :style="(Number(user?.allow_out) === 1) ? {
          borderRadius: 0,
          borderBottom: '1px solid #dadada'
        } : {}"
      >
        <b-card
          class="bg-transparent"
          body-class="p-0 d-flex flex-column flex-md-row min-h-300px rounded shadow-none"
        >
          <div
            class="d-flex flex-column justify-content-between flex-grow-1 px-4 pb-4"
            :style="{
              backgroundImage: `url(${require('@/assets/images/store.png')})`,
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '200px'
            }"
          >
            <div>
              <h2 class="text-primary font-weight-bolder">
                Good {{ greetings }}, {{ user?.name }}!
              </h2>
              <h6 class="h6">
                Here’s what happening with your store today
              </h6>
            </div>

            <b-row
              class="min-h-250px mb-md-0 mb-5 pb-md-0 pb-5"
            >

              <b-col
                class="mt-1 px-1"
                cols="12"
                md="3"
              >
                <router-link :to="{ name: 'user.purchase-orders.requests', params: { requester: 'pending' }}">
                  <SimpleCard
                    :loading="fetching"
                    :count="requests.pending"
                    title="Pending"
                    sub-title="PO Requests"
                    variant="dark"
                    icon="fad fa-file-lines"
                  />
                </router-link>
              </b-col>

              <b-col
                class="mt-1 px-1"
                cols="12"
                md="3"
              >
                <router-link :to="{ name: 'user.purchase-orders.requests', params: { requester: 'pending' }}">
                  <SimpleCard
                    :loading="fetching"
                    :count="requests.processing"
                    title="Processing"
                    sub-title="PO Requests"
                    variant="warning"
                    icon="fad fa-file-contract"
                  />
                </router-link>
              </b-col>

              <b-col
                class="mt-1 px-1"
                cols="12"
                md="3"
              >
                <router-link :to="{ name: 'user.purchase-orders.requests', params: { requester: 'histories', status: 'closed' }}">
                  <SimpleCard
                    :loading="fetching"
                    :count="requests.closed"
                    title="Closed"
                    sub-title="PO Requests"
                    variant="success"
                    icon="fad fa-file-check"
                  />
                </router-link>
              </b-col>

              <b-col
                class="mt-1 px-1"
                cols="12"
                md="3"
              >
                <router-link :to="{ name: 'user.purchase-orders.requests', params: { requester: 'histories', status: 'disapproved' }}">
                  <SimpleCard
                    :loading="fetching"
                    :count="requests.disapproved"
                    title="Disapproved"
                    sub-title="PO Requests"
                    variant="danger"
                    icon="fad fa-file-times"
                  />
                </router-link>
              </b-col>

              <b-col
                v-if="Number(user?.allow_in) === 1"
                class="mt-1 px-1"
                cols="12"
                md="3"
              >
                <router-link :to="{ name: 'user.purchase-orders.receiving', params: { receiver: 'pending' }}">
                  <SimpleCard
                    :loading="fetching"
                    :count="receiving.pending"
                    title="Receiving"
                    sub-title="PO Requests"
                    variant="dark"
                    icon="fad fa-file"
                  />
                </router-link>
              </b-col>

              <b-col
                v-if="Number(user?.allow_in) === 1"
                class="mt-1 px-1"
                cols="12"
                md="3"
              >
                <router-link :to="{ name: 'user.purchase-orders.receiving', params: { receiver: 'histories' }}">
                  <SimpleCard
                    :loading="fetching"
                    :count="receiving.processed"
                    title="Received"
                    sub-title="PO Requests"
                    variant="success"
                    icon="fad fa-file-check"
                  />
                </router-link>
              </b-col>

              <b-col
                class="mt-1 px-1"
                cols="12"
                md="3"
              >
                <router-link :to="{ name: 'user.purchase-orders.requests', params: { requester: 'histories', status: 'canceled' }}">
                  <SimpleCard
                    :loading="fetching"
                    :count="requests.canceled"
                    title="Canceled"
                    sub-title="PO Requests"
                    variant="danger"
                    icon="fad fa-file-times"
                  />
                </router-link>
              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>

      <b-col
        v-if="Number(user?.allow_out) === 1"
        cols="12"
        class="px-0"
      >
        <b-row
          class="py-2 mx-0"
          style="border-radius: 0; border-bottom: 1px solid #dadada;"
        >
          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'user.purchase-orders.approvals', params: { approver: 'pending' }}">
              <Card
                :loading="fetching"
                :count="approvals.pending"
                :border-right="borderRight"
                title="Pending"
                sub-title="PO Approvals"
                variant="dark"
                icon="fad fa-file-lines"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'user.purchase-orders.approvals', params: { approver: 'histories', status: 'approved' }}">
              <Card
                :loading="fetching"
                :count="approvals.approved"
                :border-right="borderRight"
                title="Approved"
                sub-title="PO Approvals"
                variant="success"
                icon="fad fa-file-check"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'user.purchase-orders.approvals', params: { approver: 'histories', status: 'disapproved' }}">
              <Card
                :loading="fetching"
                :count="approvals.disapproved"
                title="Disapproved"
                sub-title="PO Approvals"
                variant="danger"
                icon="fad fa-file-times"
              />
            </router-link>
          </b-col>

        </b-row>
      </b-col>

      <b-col
        v-if="Number(user?.allow_out) === 1"
        cols="12"
        class="px-0"
      >
        <b-row
          class="py-2 mx-0"
        >
          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'user.sap.posting', params: { poster: 'pending' }}">
              <Card
                :loading="fetching"
                :count="posting.pending"
                :border-right="borderRight"
                title="Pending"
                sub-title="SAP Posting"
                variant="dark"
                icon="fad fa-file-lines"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'user.sap.posting', params: { poster: 'unposted' }}">
              <Card
                :loading="fetching"
                :count="posting.unposted"
                :border-right="borderRight"
                title="Unposted"
                sub-title="SAP Posting"
                variant="warning"
                icon="fad fa-file-contract"
              />
            </router-link>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <router-link :to="{ name: 'user.sap.posting', params: { poster: 'histories' }}">
              <Card
                :loading="fetching"
                :count="posting.posted"
                title="Posted"
                sub-title="SAP Posting"
                variant="success"
                icon="fad fa-file-check"
              />
            </router-link>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import createDate from 'moment'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import { SUserDashboard } from '@/services'
import SimpleCard from '@/components/custom/SimpleCard'
import Card from '@/components/custom/Card'

export default {

  name: 'UserDashboard',

  middleware: ['auth', 'user'],

  components: {
    SimpleCard,
    Card
  },

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      borderRight: !this.isMobile(),
      requests: {
        pending: 0,
        processing: 0,
        disapproved: 0,
        canceled: 0,
        closed: 0,
        draft: 0
      },
      approvals: {
        pending: 0,
        disapproved: 0,
        approved: 0
      },
      posting: {
        pending: 0,
        unposted: 0,
        posted: 0
      },
      receiving: {
        pending: 0,
        processed: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    greetings () {
      const currentHourOfTheDay = createDate().hour()

      if (currentHourOfTheDay >= 3 && currentHourOfTheDay < 12) {
        return 'Morning'
      } else if (currentHourOfTheDay >= 12 && currentHourOfTheDay < 1) {
        return 'Noon'
      } else if (currentHourOfTheDay >= 1 && currentHourOfTheDay < 18) {
        return 'Afternoon'
      } else if (currentHourOfTheDay >= 18 && currentHourOfTheDay < 20) {
        return 'Evening'
      } else {
        return 'Night'
      }
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {
    getStatistics () {
      this.fetching = true
      return SUserDashboard.getStatistics().then(({ data }) => {
        // REQUESTS
        this.requests.draft = data.total_draft
        this.requests.pending = data.total_pending
        this.requests.processing = data.total_processing
        this.requests.closed = data.total_closed
        this.requests.disapproved = data.total_disapproved
        this.requests.canceled = data.total_canceled
        // APPROVALS
        this.approvals.pending = data.approver_approval_pending
        this.approvals.approved = data.approver_approval_approved
        this.approvals.disapproved = data.approver_approval_disapproved
        // POSTING
        this.posting.pending = data.approver_posting_pending
        this.posting.unposted = data.approver_posting_unposted
        this.posting.posted = data.approver_posting_posted
        // RECEIVING
        this.receiving.pending = data.approver_receiving_pending
        this.receiving.processed = data.approver_receiving_processed
      }).catch(() => {
        // REQUESTS
        this.requests.draft = 0
        this.requests.pending = 0
        this.requests.processing = 0
        this.requests.approved = 0
        this.requests.disapproved = 0
        this.requests.canceled = 0
        // APPROVALS
        this.approvals.pending = 0
        this.approvals.approved = 0
        this.approvals.disapproved = 0
        // POSTING
        this.posting.pending = 0
        this.posting.unposted = 0
        this.posting.posted = 0
        // RECEIVING
        this.receiving.pending = 0
        this.receiving.processed = 0
      }).finally(() => {
        this.fetching = false
      })
    }
  }
}
</script>
<style scoped>

  *.fa-square {
    position: absolute;
    transform: rotate(65deg) !important;
    font-size: 48px;
    left: 10px;
    bottom: 5px;
    color: var(--iq-warning-light);
  }

  *.font-circle {
    position: absolute;
    height: 48px;
    width: 48px;
    border: 2px solid var(--iq-warning-light);
    right: 5px;
    top: 0px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  *.font-inset {
    font-size: 24px;
    color: var(--iq-warning);
  }
</style>
