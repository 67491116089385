<template>
  <b-card
    v-if="loading"
    class="bg-transparent"
    body-class="p-0 d-flex min-h-125px rounded shadow-none bg-transparent"
  >
    <div
      class="flex-grow-1 d-flex flex-row align-items-center p-2"
    >
      <div class="position-relative min-h-75px min-w-75px max-h-75px">
        <span :class="['fa-solid fa-square ', textVariantClass]" />
        <div :class="['font-circle', borderVariantClass]">
          <span
            :class="['font-inset fad fa-spinner fa-spin', textVariantClass]"
          />
        </div>
      </div>
      <div class="flex-grow-1 px-2 d-flex flex-column justify-content-end">
        <div class="d-flex flex-row align-items-center min-h-45px">
          <b-skeleton
            animation="wave"
            width="40%"
            :variant="variant"
            class="min-h-30px"
          />
        </div>
        <b-skeleton
          animation="wave"
          width="80%"
          :variant="variant"
          class="min-h-20px"
        />
        <b-skeleton
          animation="wave"
          width="40%"
          variant="light-dark"
          class="min-h-10px"
        />
      </div>
    </div>
  </b-card>

  <b-card
    v-else
    class="bg-transparent"
    body-class="p-0 d-flex min-h-125px rounded shadow-none bg-transparent"
  >
    <div
      class="flex-grow-1 d-flex flex-row align-items-center p-2"
    >
      <div class="position-relative min-h-75px min-w-75px max-h-75px">
        <span :class="['fa-solid fa-square ', textVariantClass]" />
        <div :class="['font-circle', borderVariantClass]">
          <span
            :class="['font-inset', iconClass, textVariantClass]"
          />
        </div>
      </div>
      <div class="flex-grow-1 px-2 d-flex flex-column justify-content-end">
        <div class="d-flex flex-row align-items-center">
          <h3
            :class="['font-weight-bolder', textVariantClass]"
            v-text="animatedCounter"
          />
        </div>
        <h6
          :class="['font-weight-bolder', textVariantClass]"
          v-text="title"
        />
        <h6
          class="text-dark"
          style="font-size: 12px;"
          v-text="subTitle"
        />
      </div>
    </div>
  </b-card>
</template>
<script>
import gsap from 'gsap'

export default {
  name: 'SimpleCard',

  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    title: {
      type: String,
      default: () => ''
    },
    subTitle: {
      type: String,
      default: () => ''
    },
    count: {
      type: [Number, String],
      default: () => 0
    },
    variant: {
      type: String,
      default: () => 'primary'
    },
    icon: {
      type: String,
      default: () => 'fad fa-file'
    }
  },

  data () {
    return {
      tweenedNumber: this.count
    }
  },

  computed: {
    textVariantClass () {
      if (this.variant) {
        return `text-${this.variant}`
      }
      return null
    },
    iconClass () {
      if (this.variant) {
        return this.icon
      }
      return null
    },
    borderVariantClass () {
      if (this.variant) {
        return `border-${this.variant}`
      }
      return null
    },

    animatedCounter () {
      return this.tweenedNumber.toFixed(0)
    }
  },

  watch: {
    'count' (value) {
      gsap.to(this.$data, { duration: 1, tweenedNumber: value })
    }
  }
}
</script>
<style scoped>

  *.fa-square {
    position: absolute;
    transform: rotate(65deg) !important;
    font-size: 48px;
    left: 10px;
    bottom: 5px;
    color: var(--iq-warning-light);
  }

  *.font-circle {
    position: absolute;
    height: 48px;
    width: 48px;
    border: 2px solid var(--iq-warning-light);
    right: 5px;
    top: 0px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  *.font-inset {
    font-size: 24px;
    color: var(--iq-warning);
  }
</style>
